import UtilsInterface from "./UtilsInterface";
import {FILTER_DEFAULTS} from "../../modules/patients/constants"
import {HttpStatusCode} from "../../constants"

const Utils: UtilsInterface = Object.create(null);

/**
 * Encode a string to base64
 * @param string
 */
Utils.base64Encode = function base64Encode(string: string): string {
    if (typeof window === "undefined") {
        return new Buffer(string).toString("base64")
    }

    return btoa(string)
};

/**
 * Capitalize input string
 *
 * @param {string} input
 * @returns {string}
 */
Utils.capitalize = function capitalize(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1)
};

/**
 * Formik value validation callback
 *
 * @param {string} value
 * @returns {string}
 */
Utils.validateRequired = function validateRequired(value: string) {
    if (value.length === 0) {
        return "Required."
    }
};

/**
 * Formik age validation callback
 *
 * @param {string} age
 * @returns {string}
 */
Utils.validateAge = function validateAge(age: string) {
    if (age.length > 3 || parseInt(age) > FILTER_DEFAULTS.ageTo) {
        return `Please enter value between ${FILTER_DEFAULTS.ageFrom + 1} and ${FILTER_DEFAULTS.ageTo}.`
    }
};

/**
 * Formik age validation callback
 *
 * @param {string} phone
 * @returns {string}
 */
Utils.validatePhone = function validatePhone(phone: string) {
    if (phone.length < 9 || phone.length > 12) {
        return "Please enter valid phone number"
    }
};

/**
 * Formik username validation callback
 *
 * @param {string} username
 * @returns {string}
 */
Utils.validateUsername = function validateUsername(username: string) {
    const required = Utils.validateRequired(username);

    if (typeof required !== "undefined") {
        return required;
    } else if (username.length < 2) {
        return "Name is too short";
    } else if (!username.match(/^[A-Za-z\s]*$/)) {
        return "Name can include only letters"
    } else if (username.length > 30) {
        return "Name is too long"
    }
};

/**
 * Formik email validation callback
 *
 * @param {string} email
 * @returns {string}
 */
Utils.validateEmail = function validateEmail(email: string): string {
    if (!email) {
        return "Required!";
    } else if (false === /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return "Invalid email address!";
    }
};

/**
 * Formik password validation callback
 *
 * @param {string} password
 * @returns {string}
 */
Utils.validatePassword = function validatePassword(password: string): string {
    if (!password) {
        return "Password is required!";
    } else if (password.length < 8) {
        return "Password is too short";
    } else if (password.length > 25) {
        return "Password is too long";
    } else if (password.trim() !== password) {
        return "Your password cannot start or end with a blank space"
    }
};

Utils.validateConfirmPassword = function validateConfirmPassword(value: string, password: string): string {
    if (!value) return "Confirm your new password"
    if (value !== password) {
        return "Passwords do not match";
    }
};

/**
 * Formik firstname validation callback
 *
 * @returns {string}
 * @param name
 */
 Utils.validatePasswordOption = function validatePasswordOption(password: string): string {
    if (password.length !== 0) {
        if (password.length < 8) {
            return "Password is too short";
        } else if (password.length > 25) {
            return "Password is too long";
        } else if (password.trim() !== password) {
            return "Your password cannot start or end with a blank space"
        }
    }
};

/**
 * Formik firstname validation callback
 *
 * @returns {string}
 * @param name
 */
Utils.validateFirstName = function validateFirstName(name: string): string {
    if (!name) {
        return "First name is required!";
    } else if (!name.match(/^[A-Za-z\s]*$/)) {
        return "Name can include only letters";
    } else if (name.length < 2) {
        return "Name is too short"
    } else if (name.length > 20) {
        return "Name is too long"
    }
};

/**
 * Formik lastname validation callback
 *
 * @returns {string}
 * @param name
 */
Utils.validateLastName = function validateLastName(name: string): string {
    if (name.length !== 0) {
        if (!name.match(/^[A-Za-z\s]*$/)) {
            return "Surname can include only letters";
        } else if (name.length < 2) {
            return "Surname is too short"
        } else if (name.length > 20) {
            return "Surname is too long"
        }
    }
};

/**
 * Get first letters from user firstname and lastname
 *
 * @param firstname
 * @param lastname
 */
Utils.getUserFirstLetters = function getUserFirstLetters(firstname, lastname = null): string {
    if (firstname === null && lastname === null) {
        return "U"
    }
    if (lastname === null) {
        const firstnameArray = firstname.trim().split(" ").filter(a => a !== "");
        if (!firstnameArray.length) {
            return "U"
        }
        const firstItemFirstLetter = firstnameArray[0].charAt(0).toUpperCase();
        const lastItemFirstLetter = firstnameArray[firstnameArray.length - 1].charAt(0).toUpperCase();

        if (firstnameArray.length === 1 || typeof lastItemFirstLetter === "undefined") {
            return `${firstItemFirstLetter}`;
        }

        return `${firstItemFirstLetter}${lastItemFirstLetter}`
    }

    return `${firstname.charAt(0).toUpperCase()}${lastname.charAt(0).toUpperCase()}`
};

/**
 * Get approximate age from date of birth
 *
 * @param date
 */
Utils.getYearsFromDateOfBirth = function getYearsFromDateOfBirth(date: string) {
    const yearOfBirth = new Date(date).getFullYear();
    const yearToday = new Date().getFullYear();

    return yearToday - yearOfBirth
};

/**
 * Format date
 *
 * @param format
 * @param dateString
 */
Utils.formatDate = function formatDate(format: string, dateString?: string): string {
    let result = format;
    const date = typeof dateString !== "undefined" ? new Date(dateString) : new Date();
    const month = date.getMonth();
    const hours = date.getHours();
    const config: { [key: string]: number | string } = {
        "M+": month + 1,
        "d+": date.getDate(),
        "h+": hours,
        "H+": hours > 12 ? hours - 12 : hours,
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
        "q+": Math.floor((month + 3) / 3),
        "S": date.getMilliseconds(),
        "a+": hours > 11 ? "PM" : "AM"
    };

    if (/(y+)/.test(result)) {
        result = result.replace(
            RegExp.$1,
            date.getFullYear().toString().substr(4 - RegExp.$1.length),
        );
    }

    for (let t in config) {
        if (new RegExp("(" + t + ")").test(result)) {
            result = result.replace(RegExp.$1, 1 == RegExp.$1.length
                ? ("000" + config[t]).substr(config[t].toString().length)
                : ("00" + config[t]).substr(config[t].toString().length));
        }
    }

    return result;
};

/**
 * @param message
 */
const getValidationMessage = (message: string): string => {
    switch (message) {
        case "Bad credentials":
            return "Email or password is incorrect";
        case "User with email or phone already exists":
            return "User with this email already exists";
        default:
            return message;
    }
};

/**
 * @param status
 * @param message
 */
const getClientErrorMessage = (status: number, message: string): string => {
    switch (status) {
        case HttpStatusCode.BAD_REQUEST:
            return getValidationMessage(message);
        case HttpStatusCode.INTERNAL_SERVER_ERROR:
            return "Something went wrong on our servers. Please try again later";
        default:
            return "Something went wrong on our servers. Please try again later";
    }
};

Utils.getServerErrorMessage = err => {
    const {response, status} = err

    if (typeof response === "undefined" || response === null) {
        return "Something went wrong on our servers. Please try again later"
    }

    let ms = typeof response.message !== "undefined"
        ? response.message
        : response.error_description;

    return getClientErrorMessage(status, ms)
};

export default Utils