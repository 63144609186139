// Models
import {AppState} from "../../../../store/models"

// Constants
import {StoreKeys} from "../../../../store/constants"

export function authStateSelector(state: AppState) {
    const {
        user,
        token,
    } = state[StoreKeys.AUTHORIZATION];

    return {
        user,
        token,
    };
}

export function serverErrorStateSelector(state: AppState) {
    const {serverError} = state[StoreKeys.AUTHORIZATION]

    return {
        serverError,
    }
}

export function signInStateSelector(state: AppState) {
    const {signingIn} = state[StoreKeys.AUTHORIZATION]

    return {
        signingIn,
    }
}

export const isDemoStateSelector = (state: AppState) => {
    const {isDemo} = state[StoreKeys.AUTHORIZATION]

    return {
        isDemo,
    }
}

export function signUpStateSelector(state: AppState) {
    const {signingUp, signedUp} = state[StoreKeys.AUTHORIZATION]

    return {
        signingUp,
        signedUp,
    }
}

export function resetPasswordStateSelector(state: AppState) {
    const {passwordResetSending, passwordResetSent} = state[StoreKeys.AUTHORIZATION]

    return {
        passwordResetSending,
        passwordResetSent,
    }
}

export function changePasswordStateSelector(state: AppState) {
    const {passwordChangeSending, passwordChangeSent} = state[StoreKeys.AUTHORIZATION]

    return {
        passwordChangeSending,
        passwordChangeSent,
    }
}

export function changeUserPasswordStateSelector(state: AppState) {
    const {userPasswordChanging, userPasswordChanged} = state[StoreKeys.AUTHORIZATION]

    return {
        userPasswordChanging,
        userPasswordChanged,
    }
}

export function userSelector(state: AppState) {
    const {user} = state[StoreKeys.AUTHORIZATION];

    return {user}
}

export function userEditingSelector(state: AppState) {
    return state[StoreKeys.AUTHORIZATION].userEditing
}
