import {Sex} from "../../../constants"

export const PatientsEndpoint = {
    ALL: "auth/patients",
    RECENT: "auth/patients/recent",
    SINGLE: "auth/patients/{{patientId}}",
    CREATE: "auth/user/patient",
    UPDATE: "auth/user/{{patientId}}",
    PATIENT_RECORDS: "record/user/{patientId}/record",
    PATIENT_OVERALL: "record/user/{patientId}/overall-chart",
    DOCTOR_STATS: "record/doctor/stats",
    GENERATE_CODE: "auth/user/access/code",
};

export const SEARCH_DEFAULTS = {
    size: 100,
    sort: "p.firstname,asc",
    query: "",
};

export const PAGINATION_DEFAULTS = {
    currentPage: 0,
    totalElements: 0,
    totalPages: 1,
};

export const FILTER_DEFAULTS = {
    ageTo: 120,
    ageFrom: 0,
    sex: Sex.BOTH,
    groupIds: "",
};

export const PatientTabs = {
    RECENT: {
        key: "RECENT",
        label: "Recent patients",
        value: "RECENT",
    },
    ALL: {
        key: "ALL",
        label: "All patients",
        value: "ALL",
    },
}
