export const errorPayload = (error: unknown) => ({error});
export const idPayload = (id: string) => ({id});
export const queryPayload = (query: string) => ({query});
export const pagePayload = (page: string) => ({page});
export const totalPayload = (totalElements: number, totalPages: number) => ({totalElements, totalPages});
export const totalPagesPayload = (totalPages: number) => ({totalPages});
export const totalElementsPayload = (totalElements: number) => ({totalElements});

export const entitiesArrayToObjectById = <T extends { id: string }>(entities: Array<T>) => {
    return entities.reduce((curr, next) => {
        return {
            entitiesById: {
                ...curr.entitiesById,
                [next.id]: next,
            },
            entitiesIds: [
                ...curr.entitiesIds,
                next.id,
            ],
        }
    }, {entitiesById: {}, entitiesIds: []})
};
