export const StoreKeys = {
    AUTHORIZATION: "auth",
    PATIENTS: "patients",
    PATIENTS_FACTORY: "patients-factory",
    SINGLE_PATIENT: "single-patient",
    SINGLE_RECORD: "single-record",
    GROUPS: "groups",
    RECORDS: "records",
    COMMON: "common",
};
